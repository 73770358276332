<template>
    <v-container fluid style="margin-bottom: 100px">
        <v-row>
            <v-col cols="12">
                <v-breadcrumbs :items="breadcumbs">
                    <template v-slot:item="{ item }">
                        <v-breadcrumbs-item
                            :to="item.href"
                            class="text-subtitle-2 crumb-item"
                            :disabled="item.disabled"
                            exact
                        >
                            {{ item.text }}
                        </v-breadcrumbs-item>
                    </template>
                </v-breadcrumbs>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <v-card>
                    <v-card-title class="pb-1 pt-1">
                        Filter
                    </v-card-title>
                    <!-- <v-progress-linear
                        :active="loading"
                        :indeterminate="loading"
                        absolute
                        bottom
                        color="deep-purple accent-4"
                    ></v-progress-linear> -->
                    <v-container fluid>
                        <v-row>
                            <v-col cols="12" xs="12" sm="5" md="2">
                                <v-autocomplete  
                                    class="ma-0 pa-0" 
                                    solo
                                    clearable 
                                    v-model="region" 
                                    :disabled="disabledRegion"
                                    :items="regions" 
                                    @change="(event) => getOffice(event)"
                                    item-value="region" 
                                    item-text="region" 
                                    label="Region" 
                                    hide-details=true>
                                </v-autocomplete>
                                <!-- @change="(event) => getOffice(event)" -->
                            </v-col>
                            <v-col cols="12" xs="12" sm="5" md="2">
                                <v-autocomplete 
                                    class="ma-0 pa-0" 
                                    solo 
                                    clearable 
                                    v-model="office" 
                                    :disabled="disabledOrder"
                                    :items="offices" 
                                    item-value="office_id" 
                                    item-text="office"
                                    label="Order From"
                                    hide-details=true>
                                </v-autocomplete>
                            </v-col>
                            <v-col cols="12" xs="12" sm="5" md="2">
                                <v-autocomplete 
                                    class="ma-0 pa-0" 
                                    solo 
                                    clearable 
                                    v-model="mill" 
                                    :items="mills" 
                                    item-value="mill_id" 
                                    item-text="mill_name"
                                    label="Ship From"
                                    hide-details=true>
                                </v-autocomplete>
                            </v-col>
                            <v-col cols="12" xs="12" sm="5" md="2">
                                <v-autocomplete 
                                    class="ma-0 pa-0" 
                                    solo 
                                    clearable 
                                    v-model="type" 
                                    :items="types" 
                                    label="Type"
                                    hide-details=true>
                                </v-autocomplete>
                            </v-col>
                            <v-col cols="12" xs="12" sm="5" md="2">
                                <v-autocomplete 
                                    class="ma-0 pa-0" 
                                    solo 
                                    clearable 
                                    v-model="sales" 
                                    :disabled="disabledSales"
                                    :items="saleses" 
                                    item-value="SalesId" 
                                    item-text="NamaSales"
                                    label="Sales"
                                    hide-details=true>
                                </v-autocomplete>
                            </v-col>
                            <v-col cols="12" xs="12" sm="5" md="2">
                                <v-autocomplete 
                                    class="ma-0 pa-0" 
                                    solo 
                                    clearable 
                                    v-model="category" 
                                    :items="categories" 
                                    item-value="category_id" 
                                    item-text="cat_desc"
                                    label="Category"
                                    hide-details=true>
                                </v-autocomplete>
                            </v-col>
                            <v-col cols="12" xs="12" sm="6" md="2">
                                <v-menu
                                    ref="modal"
                                    v-model="modal"
                                    :close-on-content-click="false"
                                    transition="scale-transition"
                                    offset-y
                                    max-width="290px"
                                    min-width="auto"
                                    >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                        solo
                                        v-model="start_date"
                                        label="Start Period"
                                        persistent-hint
                                        prepend-inner-icon="mdi-calendar"
                                        v-bind="attrs"
                                        v-on="on"
                                        class="ma-0 pa-0"
                                        hide-details=true
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker
                                        v-model="start_date"
                                        type="month"
                                        no-title
                                        @input="modal = false"
                                    ></v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col cols="12" xs="12" sm="6" md="2">
                                <v-menu
                                    ref="modal_to"
                                    v-model="modal_to"
                                    :close-on-content-click="false"
                                    transition="scale-transition"
                                    offset-y
                                    max-width="290px"
                                    min-width="auto"
                                    >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                        solo
                                        v-model="end_date"
                                        label="End Period"
                                        persistent-hint
                                        prepend-inner-icon="mdi-calendar"
                                        v-bind="attrs"
                                        v-on="on"
                                        class="ma-0 pa-0"
                                        hide-details=true
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker
                                        v-model="end_date"
                                        type="month"
                                        no-title
                                        @input="modal_to = false"
                                    ></v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col cols="12" xs="12" sm="3" md="1">
                                <v-text-field
                                    solo
                                    clearable
                                    v-model="top"
                                    label="Top"
                                ></v-text-field>
                            </v-col>

                            <!-- <v-col cols="12" xs="12" sm="3" md="7">
                            </v-col> -->
                        
                            <v-col cols="12" xs="12" sm="3" md="2">
                                <v-btn 
                                    :loading="searchOn"
                                    :disabled="searchOn"
                                    color="info" elevation="2" large @click="getTopEnd()">Search
                                </v-btn>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" xs="12" sm="12" md="6">
                                <v-card>
                                    <v-card-title>Top {{top}} Best</v-card-title>
                                    <v-card-text>
                                        <v-container fluid>
                                            <v-row>
                                                <v-col cols="12">
                                                    <v-data-table
                                                        fixed-header
                                                        :headers="headerDataTable"
                                                        :items="topTenBest"
                                                        :loading="loading"
                                                        :items-per-page="topNumber"
                                                        hide-default-footer
                                                        loading-text="Please wait, retrieving data"
                                                        class="text-sm-left">
                                                    </v-data-table>

                                                </v-col>
                                            </v-row>
                                        </v-container>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                            <v-col cols="12" xs="12" sm="12" md="6">
                                <v-card >
                                    <v-card-title>Top {{top}} Worst</v-card-title>
                                    <v-card-text>
                                        <v-container fluid>
                                            <v-row>
                                                <v-col cols="12">
                                                    <v-data-table
                                                        fixed-header
                                                        :headers="headerDataTableWorst"
                                                        :items="topTenWorst"
                                                        :loading="loading"
                                                        :items-per-page="topNumber"
                                                        hide-default-footer
                                                        loading-text="Please wait, retrieving data"
                                                        class="font-weight: 100">
                                                    </v-data-table>
                                                </v-col>
                                            </v-row>
                                        </v-container>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import {master} from "../../../backend-api/kmb/master"
import {order} from "../../../backend-api/kmb/order"

export default {
    data() {
        return {
            snackbar: {
                color: null,
                icon: null,
                mode: null,
                position: "top",
                text: null,
                timeout: 7500,
                title: null,
                visible: false
            },
            breadcumbs: [
                {
                text: 'KMB',
                disabled: false,
                href: '/admin/kmb/',
                },
                {
                text: 'Dashboard',
                disabled: true,
                href: 'breadcrumbs_link_2',
                },
                {
                text: 'Order Dashboard',
                disabled: true,
                href: 'breadcrumbs_link_2',
                },
            ],
            loading:false,
            filterRadio:"Periodicaly",
            region:'',
            regions:[],
            office:'',
            offices:[],
            mill:'',
            mills:[],
            type:'',
            types:['Project', 'Non Project'],
            sales:'',
            saleses:[],
            category:'',
            categories:[],
            modal: false,
            modal_to: false,
            start_date:'',
            end_date:'',
            top:'10',
            topNumber:10,
            // searchRegion:null,
            // searchOffice:null,
            // searchMill:null,
            // searchbarangJadi:null,
            // searchSales:null,
            topTenBest:[],
            topTenWorst:[],
            headerDataTable:[
                {
                    text: 'Rank',
                    align: 'start',
                    sortable: false,
                    value: 'no',
                    class: "grey"
                },
                {
                    text: 'Nama Barang',
                    sortable: false,
                    value: 'namabarang',
                    class: "grey text-align: center"
                },
                { 
                    text: 'Qty Order (Pcs)', 
                    value: 'qty', 
                    sortable: false, 
                    class: "grey text-align: center"},
            ],
            headerDataTableWorst:[
                {
                    text: 'Rank',
                    align: 'start',
                    sortable: false,
                    value: 'no',
                    class:'error',
                },
                {
                    text: 'Nama Barang',
                    sortable: false,
                    value: 'namabarang',
                    class:'error text-align: center',
                },
                { 
                    text: 'Qty Order (Pcs)', 
                    value: 'qty', 
                    sortable: false, 
                    class:'error text-align: center',},
            ],
            data_var: {
                entity_id : 'KMB',
                appl_id : 'WEBKMB'
            },
            editable:false,
            varValue:null,
            searchOn:false,
            disabledRegion:false,
            disabledOrder:false,
            disabledSales:false,
        }
    },
    mounted() {
        var today = new Date();
        var yearMonth = today.toISOString().slice(0, 7).replace('-', '');
        var a = yearMonth - 1;
        var year = a.toString().substring(0,4);
        var month = a.toString().substring(4,6);
        var periode = year + "-"+month
        this.start_date = periode
        this.end_date = periode
        this.getCond()
        this.getBarangJadi()
        // this.getEnvConf()
    },
    computed:{
        formattedData() {
            return this.topTenData.map(item => ({
                ...item,
                wgt: this.formatNumber(item.wgt, 0, 0),
                total_price : this.formatNumber(item.total_price, 2, 2)
            }));
        },
        formattedDetailData() {
            return this.detailData.map(item => ({
                ...item,
                wgt: this.formatNumber(item.wgt, 0, 0),
                unit_price: this.formatNumber(item.unit_price, 2, 2),
                total_price : this.formatNumber(item.total_price, 2, 2)
            }));
        }
    },
    methods:{
        formatNumber(number, minFrac, maxFrac) {
            var a = parseFloat(number)
            return a.toLocaleString(undefined, {
                minimumFractionDigits: minFrac,
                maximumFractionDigits: maxFrac
            });
        },
        async getCond(){
            await axios.get(`${process.env.VUE_APP_URL}/api/kmb/OrderReport`, { 
                headers: { 
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                } 
            }).then(res => {
                this.regions = res.data['region']
                if(this.regions.length == 1){
                    this.disabledRegion = true
                    this.region = this.regions[0]['region']
                }
                this.offices = res.data['office']
                if(this.offices.length == 1){
                    this.disabledOrder = true 
                    this.office = this.offices[0]['office_id']
                    console.log(this.offices[0])
                }
                this.mills = res.data['mill']
                this.saleses = res.data['sales']
                if(this.saleses.length == 1){
                    this.disabledSales = true
                    this.sales = this.saleses[0]['SalesId']
                }
            })
        },
        async getEnvConf(){
            let data = this.data_var;

            this.$store.dispatch("getVariabelUser", { data }).then(res => {
                for (let i = 0; i < res.data.data.length; i++) {
                    // console.log(res.data.data[i])
                    if (res.data.data[i]['var_id'] === 'TOPTENORDER') {
                        this.varValue = res.data.data[i]['var_value']
                        if (this.varValue != null) {
                            this.editable=true
                        } else {
                            this.editable=false
                        }
                    }
                }
                console.log(`Editable = ${this.editable}`)
                if(this.editable){
                    this.getSales(this.$store.state.user.username)
                } else {
                    this.getRegion()
                    this.getOffice()
                    this.getMill()
                    this.getBarangJadi()
                    this.getSales()
                }
                // this.getTopEnd()
            })
        },
        async getRegion(value){
        //   var url = `?region=${value}`;
        var url = ''
          if(this.editable){
            console.log(value)
            url = `${url}?office_id=${value}`
          }
          
          var data = await master.fetchRegion(url, null, false, false, false)
          this.regions = data.data.data;
          if(this.editable){
            this.region = this.regions[0]['region']
          }
        },
        async getOffice(value){
        //   var url = `?office=${value}`;
            if(!this.disabledRegion){
                var url = ''
                if((this.region != '' || this.region != null) && this.editable == false){
                    url = `${url}?region=${this.region}`
                }
                if(this.editable){
                    url = `${url}?office_id=${value}`
                }
                var data = await master.fetchOffice(url, null, false, false, false)
                this.offices = data.data.data;
                if(this.editable){
                    console.log(this.office)
                    this.getRegion(value)
                }
            }
        },
        async getMill(value){
        //   var url = `?mill_name=${value}`;
        var url = ''
          var data = await master.fetchMill(url, null, false, false, false)
          this.mills = data.data.data;
        },
        async getBarangJadi(value){
        //   var url = `?cat_desc=${value}`;
        var url = ''
          var data = await master.fetchCategory(url, null, false, false, false)
          this.categories = data.data.data;
        },
        async getSales(value){
        //   var url = `?NamaSales=${value}&active_flag=Y`;
          var url = '?active_flag=Y'
          if(this.office != '' || this.office != null){
            url = `${url}&office_id=${this.office}`
          }
          if(value){
            url = `${url}&SalesId=${value}`
          }
          var data = await master.fetchSales(url, null, false, false, false)
          this.saleses = data.data.data;
          if(value){
            this.sales = this.saleses[0]['SalesId']
            this.office = this.saleses[0]['office_id']
          }
          if(this.editable){
            console.log(`office = ${this.office}`)
            this.getOffice(this.saleses[0]['office_id'])
          }
        },
        async getTopEnd(){
          this.loading = true
          this.searchOn = true
          this.topNumber = parseInt(this.top)
          var urlBest = '?order_sort=desc'
          var urlWorst = '?order_sort=asc'
          if(this.region != '' && this.region != null){
            urlBest = `${urlBest}&region=${this.region}`
            urlWorst = `${urlWorst}&region=${this.region}`
          }
          if(this.office != '' && this.office != null){
            urlBest = `${urlBest}&office_id=${this.office}`
            urlWorst = `${urlWorst}&office_id=${this.office}`
          }
          if(this.mill != '' && this.mill != null){
            urlBest = `${urlBest}&mill_id=${this.mill}`
            urlWorst = `${urlWorst}&mill_id=${this.mill}`
          }
          if(this.type != '' && this.type != null){
            if(this.type == 'Project'){
                urlBest = `${urlBest}&division=R`
                urlWorst = `${urlWorst}&division=R`
            }
            if(this.type == 'Non Project'){
                urlBest = `${urlBest}&division=NR`
                urlWorst = `${urlWorst}&division=NR`
            }
          }
          if(this.sales != '' && this.sales != null){
            urlBest = `${urlBest}&sales_id=${this.sales}`
            urlWorst = `${urlWorst}&sales_id=${this.sales}`
          }
        //   console.log('category = '+ this.category);
          if(this.category != '' && this.category != null){
            console.log('masuk category');
            urlBest = `${urlBest}&category_id=${this.category}`
            urlWorst = `${urlWorst}&category_id=${this.category}`
          }
        //   console.log(urlBest);
          if(this.top != '' && this.top != null){
            urlBest = `${urlBest}&top=${this.top}`
            urlWorst = `${urlWorst}&top=${this.top}`
          }
          if(this.start_date != '' && this.start_date != null){
            var sd = `${this.start_date.substring(0,4)}${this.start_date.slice(-2)}`
            urlBest = `${urlBest}&periode_pesan_start=${sd}`
            urlWorst = `${urlWorst}&periode_pesan_start=${sd}`
          }
          if(this.end_date != '' && this.end_date != null){
            var sd = `${this.end_date.substring(0,4)}${this.end_date.slice(-2)}`
            urlBest = `${urlBest}&periode_pesan_end=${sd}`
            urlWorst = `${urlWorst}&periode_pesan_end=${sd}`
          }
          
          var dataBest = await order.topEnd(urlBest, null, false, false, false)
          var topTenData = dataBest.data.data;
          this.topTenBest = []
          console.log(topTenData)
          topTenData.forEach((element, index) => {
            this.topTenBest.push({
                no: index+1,
                namabarang: element.namabarang,
                qty: this.$store.getters.convertToCurrencyNoFrac(element.qty)
            })
          })
          console.log("TOP ten best")
          console.log(this.topTenBest)
          var dataWorst = await order.topEnd(urlWorst, null, false, false, false)
          var topTenWorstData = dataWorst.data.data;
          this.topTenWorst = []
          console.log(topTenWorstData)
          topTenWorstData.forEach((element, index) => {
            this.topTenWorst.push({
                no: index+1,
                namabarang: element.namabarang,
                qty: this.$store.getters.convertToCurrencyNoFrac(element.qty)
            })
          })
          console.log("TOP ten worst")
          console.log(this.topTenWorst)
          this.loading = false
          this.searchOn = false
        },
    },
    watch:{
        // searchRegion (val) {
        //     val && val !== this.region && this.getRegion(val)
        // },
        // searchOffice (val) {
        //     val && val !== this.office && this.getOffice(val)
        // },
        // searchMill (val) {
        //     val && val !== this.mills && this.getMill(val)
        // },
        // searchbarangJadi (val) {
        //     val && val !== this.category && this.getBarangJadi(val)
        // },
        // searchSales (val) {
        //     val && val !== this.sales && this.getSales(val)
        // },
    },
}
</script>
<style>
@import url('https://fonts.googleapis.com/css2?family=Roboto+Flex:opsz,wght@8..144,300&display=swap');

.v-data-table>.v-data-table__wrapper>table>tbody>tr>td{
    font-family: 'Roboto Flex';
}
</style>